import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import ModalV5 from '../../common/Modal';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import Select from 'components/common/Select';
import { Button } from 'components/common/Button';
import {
  getUserPhysicalLocation,
  getUserLocation,
} from '../../../services/user';
import { DEFAULT_STORE } from '../constant';

const StyledModal = styled(ModalV5)`
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-body {
    padding: 30px;
  }

  .ant-modal-footer {
    background-color: var(--catskill-white);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 16px 30px;
  }
`;

interface MultiStoreProps {
  userName: any;
  stores: Array<any>;
  storeHandler: (params: any) => void;
}

const StyledWarning = styled.div`
  font-size: var(--font-size-20);
  white-space: initial;
  color: var(--red);
  font-weight: var(--font-weight-500);
  line-height: 25px;
  text-align: center;
`;

const StyledCurrentStore = styled.div`
  font-size: var(--font-size-18);
  white-space: initial;
  color: var(--black);
  font-weight: var(--font-weight-400);
  line-height: 25px;
  text-align: center;
  margin-top: 15px;
`;

const StyledSelectedStore = styled.div`
  font-size: var(--font-size-18);
  white-space: initial;
  color: var(--black);
  font-weight: var(--font-weight-400);
  line-height: 25px;
  text-align: center;
  margin-top: 15px;
`;

const StyledContinue = styled.div`
  font-size: var(--font-size-18);
  white-space: initial;
  color: var(--red);
  font-weight: var(--font-weight-500);
  line-height: 25px;
  text-align: center;
  margin-top: 15px;
`;

const MultiStore = ({
  userName,
  stores = [],
  storeHandler,
}: MultiStoreProps) => {
  const [selectedStore, setSelectedStore] = useState<any>({});
  const [defaultStoreTrim, setDefaultStoreTrim] = useState<number>();
  const [selectedOtherStore, setSelectedOtherStore] = useState<boolean>(false);
  const [machineLocation, setMachineLocation] = useState<string>();

  useEffect(() => {
    getLocation();
    defaultStoreTrim && onAutoSelectedDefaultStore(defaultStoreTrim);
  }, [defaultStoreTrim]);

  const getLocation = async () => {
    sessionStorage.removeItem('defaultStore');
    try {
      const result = await getUserLocation(); /// 'HST6909A'; //Delete After Test
      if (result?.StoreId) {
        const defaultStoreName = result?.StoreId; ///result?.data.substring(3, 7); //Delete After Test No need to trim
        const isDefaultStore = stores.find(
          (item) => item.storeId === parseInt(defaultStoreName)
        );
        isDefaultStore && setDefaultStoreTrim(parseInt(defaultStoreName));
        isDefaultStore &&
          sessionStorage.setItem('defaultStore', defaultStoreName);
        isDefaultStore && setMachineLocation(result?.StoreId);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onAutoSelectedDefaultStore = (value: any) => {
    const selectedStore = stores?.find((el: any) => el.storeId === value);
    setSelectedStore(selectedStore);
  };

  const onSelectionChange = (value: any) => {
    const selectedStore = stores?.find((el: any) => el.storeId === value);
    if (defaultStoreTrim && selectedStore !== defaultStoreTrim) {
      setSelectedOtherStore(true);
    }
    setSelectedStore(selectedStore);
  };

  const storeOptions = stores.map((el: any, index: number) => {
    return {
      key: index,
      value: el.storeId,
      label: `${el.storeName} - ${el.storeId}`,
    };
  });

  const getStoreNameID = () => {
    const { storeId, storeName } = selectedStore;
    return `${storeId} ${storeName}`;
  };

  const onHandleCancel = () => {
    setSelectedOtherStore(false);
  };

  if (selectedOtherStore) {
    return (
      <ModalV5
        width="500px"
        style={{ top: '25vh' }}
        open={true}
        closable={false}
        footer={[
          <Row key="markVulnerablePopup">
            <Col span={4} style={{ textAlign: 'left' }}>
              <Button
                itemProp="secondary"
                disabled={isEmpty(selectedStore)}
                onClick={() => storeHandler(selectedStore)}
                key="multi-store-yes"
              >
                Yes
              </Button>
            </Col>
            <Col span={10} offset={10}>
              <Button
                itemProp="secondary"
                onClick={onHandleCancel}
                key="multi-store-no"
              >
                No
              </Button>
            </Col>
          </Row>,
        ]}
      >
        <StyledWarning>{DEFAULT_STORE.WARNING}</StyledWarning>
        <StyledCurrentStore>
          {DEFAULT_STORE.CURRENT_WORKING_TXT} <b>{machineLocation}</b>
        </StyledCurrentStore>
        <StyledSelectedStore>
          {DEFAULT_STORE.DEFAULT_STORE_TXT} <b>{getStoreNameID()}</b>
        </StyledSelectedStore>
        <StyledContinue>{DEFAULT_STORE.WISH_TO_CONTINUE_TXT}</StyledContinue>
      </ModalV5>
    );
  }

  const isDisabled = () => {
    if (defaultStoreTrim) {
      return false;
    }
    return isEmpty(selectedStore);
  };

  return (
    <StyledModal
      width="600px"
      style={{ top: '25vh' }}
      open={true}
      closable={false}
      footer={[
        <Button
          disabled={isDisabled()}
          onClick={() => storeHandler(selectedStore)}
          key="multi-store-selection"
        >
          Submit
        </Button>,
      ]}
    >
      <h2>Welcome {userName}</h2>
      <h2>Please select the store you wish to work from.</h2>
      <Select
        value={defaultStoreTrim}
        onChange={onSelectionChange}
        options={storeOptions}
      />
    </StyledModal>
  );
};

export default MultiStore;
